var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "h1",
    { staticClass: "page-title mb-4" },
    [
      _vm._t("default", function() {
        return [_vm._v(_vm._s(_vm.title))]
      }),
      _vm.bookmarkable
        ? _c("BookmarkToggle", {
            staticClass: "bookmark-toggle",
            model: {
              value: _vm.internalBookmark,
              callback: function($$v) {
                _vm.internalBookmark = $$v
              },
              expression: "internalBookmark"
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }